<template>
  <div
    ref="folderModal"
    class="folder-modal"
    :class="dynamicClass"
    :style="dynamicStyle"
  >
    <div class="inner">
      <div class="btn" @click="triggle()">
        <Icon size="24" type="ios-arrow-forward" />
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'rightFolderModal',
  props: {
    config: {
      type: Object,
      required: false,
      default: () => {
        return {}
      },
    },
  },
  created() {
    let defaultConfig = {
      // 是否展开  true / false
      show: true,
      // 宽度
      width: 340,
      // 背景色
      backgroundColor: '#707070',
      // 位置 left / right
      direction: 'left',
    }
    this.$set(this, 'userConfig', _.merge({}, defaultConfig, this.config || {}))
  },
  computed: {
    // 悬浮框 可以左右设置
    dynamicClass: function () {
      console.log('this.userConfig.direction',this.userConfig.direction);
      if (this.userConfig.show && this.userConfig.direction == 'left') {
        console.log('left');
        return 'left'
      } else if (
        this.userConfig.show &&
        this.userConfig.direction == 'right'
      ) {
        console.log('right');
        return 'right'
      } else if (
        !this.userConfig.show &&
        this.userConfig.direction == 'left'
      ) {
        return 'left hidden-left'
      } else if (
        !this.userConfig.show &&
        this.userConfig.direction == 'right'
      ) {
        return 'right hidden-right'
      }
    },
    dynamicStyle: function () {
      console.log('11', this.userConfig.width)
      return {
        width: this.userConfig.width + 'px',
        backgroundColor: this.userConfig.backgroundColor,
      }
    },
  },
  data() {
    return {
      userConfig: {},
    }
  },
  methods: {
    triggle() {
      // 在切换展示的时候有些按钮需要跟随一起移动
      this.userConfig.show = !this.userConfig.show
      this.$refs['folderModal'].style[this.userConfig.direction] = this
        .userConfig.show
        ? '0'
        : '-' + (this.width || this.$refs['folderModal'].style.width)
      this.$emit('toggleFolder')
    },
    getRem(val) {
      return val + 'px'
    },
  },
}
</script>

<style lang="scss" scoped>
.right {
  right: 0;
  .btn {
    left: -24px;
  }
}
.left {
  left: 0;
  border-radius: 0;
  .btn {
    right: -24px;
    i {
      transform: rotate(180deg);
    }
  }
}
.folder-modal {
  transition: all 0.3s ease-in;
  position: absolute;
  top: 0;
  height: 100%;
  background-color: #707070;
  color: #fff;
  .inner {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .btn {
    position: absolute;
    top: 28px;
    background-color: #fff;
    width: 24px;
    height: 48px;
    cursor: pointer;
    line-height: 54px;
    i {
      transition: all 0.3s ease-in;
      color: #646464;
    }
  }
}
.hidden-right {
  transition: all 0.3s ease-in;
  .btn {
    i {
      transform: rotate(180deg);
      transition: all 0.3s ease-in;
    }
  }
}
.hidden-left {
  transition: all 0.3s ease-in;
  .btn {
    i {
      transform: rotate(0deg);
      transition: all 0.3s ease-in;
    }
  }
}
</style>
