import _ from "lodash";
export default {
  name: "filter-mixin",
  filters: {
    // 格式化过滤器
    formatSeconds(seconds) {
      let hour = parseInt(seconds / 3600);
      let minute = parseInt((seconds - 3600 * hour) / 60);
      seconds = seconds - 3600 * hour - 60 * minute;
      return (
        (hour ? hour + "h" : "") + (minute ? minute + "m" : "") + seconds + "s"
      );
    },
    formatSecondsInChinese(seconds) {
      let hour = parseInt(seconds / 3600);
      let minute = parseInt((seconds - 3600 * hour) / 60);
      seconds = seconds - 3600 * hour - 60 * minute;
      return (
        (hour ? hour + "小时" : "") +
        (minute ? minute + "分" : "") +
        seconds +
        "秒"
      );
    },
    addUnitMiles(miles) {
      return miles ? parseFloat(miles).toFixed(0) + "m" : "";
    },
    formatMiles(miles) {
      try {
        let km = parseFloat(miles / 1000).toFixed(1);
        return km >= 1 ? km + " km" : miles + "m";
      } catch {
        return "-m";
      }
    },
    formatCount(count) {
      return count + " 次";
    },
    getFirstHalfPartTime(time) {
      return time ? time.split(" ")[0] : "";
    },
    formatSpeed(speed) {
      try {
        return parseFloat(speed).toFixed(1) + "m/s";
      } catch {
        return "-m/s";
      }
    },
    formatAngle(val) {
      try {
        return parseFloat(val).toFixed(1) + "°";
      } catch {
        return "-°";
      }
    },
    formatPercent(val) {
      try {
        return parseFloat(val).toFixed(0) + "%";
      } catch {
        return "-%";
      }
    },
    formatTemperatrue(val) {
      try {
        return parseFloat(val).toFixed(0) + "°C";
      } catch {
        return "-°C";
      }
    },
    formatVoltage(val) {
      try {
        return (parseFloat(val) / 1000).toFixed(1) + "V";
      } catch {
        return "-V";
      }
    },
    formatPosition(val) {
      try {
        if (!val || val === "NaN") return "0°0′0″";
        val = parseFloat(val);
        let h = parseInt(val);
        let _m = (val - h) * 60;
        let m = parseInt(_m);
        let s = parseInt((_m - m) * 60);
        return `${h}°${m}′${s}″`;
      } catch {
        return "0°0′0″";
      }
    },
    formatPlayTime(val) {
      try {
        val = parseInt(val);
        let m = parseInt(val / 60);
        let s = val - m * 60;
        m = m < 10 ? "0" + m : m;
        s = s < 10 ? "0" + s : s;
        return m + ":" + s;
      } catch {
        return "00:00";
      }
    },
    formatByZero(val) {
      return val ? val : 0;
    },
    formatOnlyKm(miles) {
      try {
        let km = (parseFloat(miles) / 1000).toFixed(2);
        return km + "km";
      } catch {
        return "0km";
      }
    },
    format1Decimal(val) {
      try {
        if (!val || val === "NaN") return "0.0";
        return parseFloat(val).toFixed(1);
      } catch {
        return "0.0";
      }
    },
    formatAngle0(val) {
      try {
        if (!val) return "0°";
        return parseFloat(val).toFixed(0) + "°";
      } catch {
        return "0°";
      }
    },
    formatFileSize(val) {
      if (!val) return "0M";
      try {
        return (parseFloat(val) / 1024).toFixed(2) + "M";
      } catch {
        return "0M";
      }
    },
    formatVoiceLength(val) {
      if (!val) return "0s";
      try {
        return parseFloat(val).toFixed(1) + "s";
      } catch {
        return "0s";
      }
    },
    formatNull(val) {
      if (!val) return "-";
      return val;
    },
  },
};
